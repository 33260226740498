import type { Entry } from "contentful";
import { useCallback, useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";

import type { SerializeFrom } from "@remix-run/cloudflare";

import { Carousel } from "~/components/ui/carousel";
import { H2 } from "~/components/ui/text";
import { cn } from "~/lib/ui";
import { useMediaScreen } from "~/lib/utils/screens";

import type {
  TypeImageWithCtaSkeleton,
  TypeImageWithHyperlinkSkeleton,
} from "../compiled";
import type {
  CarouselContent,
  ImageWithCTAContent,
  ImageWithHyperlinkContent,
} from "../types";
import { getMaxWidthClass } from "../util";
import { ContentfulImageWithCTA } from "./image-with-cta";
import { ContentfulImageWithHyperlink } from "./image-with-hyperlink";
import { ContentfulLink } from "./link";

type props = SerializeFrom<
  Entry<
    TypeImageWithCtaSkeleton | TypeImageWithHyperlinkSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    string
  >
>;

const getTile = (image: props, key: number) => {
  return image.sys.contentType.sys.id === "imageWithCta" ? (
    <ContentfulImageWithCTA
      key={key}
      content={image as ImageWithCTAContent}
      className="rounded-lg"
    />
  ) : (
    <ContentfulImageWithHyperlink
      key={key}
      content={image as ImageWithHyperlinkContent}
      className="rounded-lg"
    />
  );
};

export const ContentfulCarousel = ({
  carouselContent,
  className = "",
}: {
  carouselContent: CarouselContent;
  className?: string;
}) => {
  const tiles = carouselContent.fields.content;
  const { isDesktop, isTablet } = useMediaScreen();
  const spaceBetweenSlides = useRef<number>(12);

  const pickNumberOfTilesForScreen = useCallback(() => {
    let numberOfTiles: number | undefined;
    switch (true) {
      case isDesktop:
        spaceBetweenSlides.current = 24;
        numberOfTiles = carouselContent.fields.numberOfColumnsDesktop;
        break;
      case isTablet:
        spaceBetweenSlides.current = 12;
        numberOfTiles = carouselContent.fields.numberOfColumnsTablet;
        break;
      default:
        spaceBetweenSlides.current = 12;
        numberOfTiles = carouselContent.fields.numberOfColumnsMobile;
        break;
    }
    return numberOfTiles || 1;
  }, [carouselContent.fields, isDesktop, isTablet]);

  const [numberOfTiles, setNumberOfTiles] = useState<number>(
    pickNumberOfTilesForScreen(),
  );

  useEffect(() => {
    setNumberOfTiles(pickNumberOfTilesForScreen());
  }, [pickNumberOfTilesForScreen]);

  // Get the Tailwind class for maxWidth
  const maxWidthClass = getMaxWidthClass(carouselContent.fields.maxWidth);

  return (
    <div className={`${className} ${maxWidthClass} mx-auto`}>
      <div className="flex flex-row items-center justify-between">
        <H2 className="font-roboto text-[26px] font-semibold uppercase leading-lg text-brand-primary-black lg:text-[32px]">
          {carouselContent.fields.title}
        </H2>
        {carouselContent.fields.link && (
          <ContentfulLink
            content={carouselContent.fields.link}
            className="text-xs font-bold leading-lg text-brand-primary-black underline lg:text-base lg:font-extrabold"
          />
        )}
      </div>
      <div
        style={
          {
            "--swiper-number-of-columns-desktop":
              carouselContent.fields.numberOfColumnsDesktop,
            "--swiper-number-of-columns-tablet":
              carouselContent.fields.numberOfColumnsTablet,
            "--swiper-number-of-columns-mobile":
              carouselContent.fields.numberOfColumnsMobile,
          } as React.CSSProperties
        }
      >
        <Carousel
          className={cn("contentful-slider mt-5", className)}
          spaceBetween={spaceBetweenSlides.current}
          slidesPerView={numberOfTiles}
          slidesPerGroup={1}
          centeredSlides={false}
          useBubblesNavigation={numberOfTiles < tiles.length}
          modules={[Navigation]}
          slideClassName={cn("w-full !h-auto [&>div]:h-full [&>div]:w-full")}
          // pagination={{
          //   bulletClass: "swiper-pagination-bullet",
          //   clickable: true,
          // }}
          draggable
        >
          {tiles?.map((tile, key) => getTile(tile, key))}
        </Carousel>
      </div>
    </div>
  );
};

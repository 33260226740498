import type { Entry } from "contentful";
import { Autoplay, Keyboard } from "swiper/modules";

import type { SerializeFrom } from "@remix-run/cloudflare";

import { Carousel } from "~/components/ui/carousel";
import { cn } from "~/lib/ui";

import type {
  TypeBannerCarouselSkeleton,
  TypeComponentHeroBannerSkeleton,
  TypeImageWithCtaSkeleton,
  TypeImageWithHyperlinkSkeleton,
} from "../compiled";
import { HeroBannerAutobarn } from "./hero-banner-autobarn";
import { ContentfulImageWithCTA } from "./image-with-cta";
import { ContentfulImageWithHyperlink } from "./image-with-hyperlink";

const CAROUSEL_DELAY = 10000;

type props = SerializeFrom<
  Entry<
    | TypeImageWithCtaSkeleton
    | TypeImageWithHyperlinkSkeleton
    | TypeComponentHeroBannerSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    string
  >
>;

// Type guard for ImageWithCta
function isImageWithCta(
  image: props,
): image is SerializeFrom<
  Entry<TypeImageWithCtaSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
> {
  return image.sys.contentType.sys.id === "imageWithCta";
}

// Type guard for ImageWithHyperlink
function isImageWithHyperlink(
  image: props,
): image is SerializeFrom<
  Entry<TypeImageWithHyperlinkSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
> {
  return image.sys.contentType.sys.id === "imageWithHyperlink";
}

// Type guard for HeroBannerAutobarn
function isComponentHeroBanner(
  image: props,
): image is SerializeFrom<
  Entry<TypeComponentHeroBannerSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
> {
  return image.sys.contentType.sys.id === "componentHeroBanner";
}

//TODO: optimize eager loading for first banner only
const getBanner = (image: props, key: number) => {
  if (isImageWithCta(image)) {
    return (
      <ContentfulImageWithCTA
        key={key}
        content={image}
        className="h-[576px] md:h-auto md:min-h-[320px]"
        textClassname={"font-roboto space-y-2 mb-2"}
        isBanner
      />
    );
  } else if (isImageWithHyperlink(image)) {
    return (
      <ContentfulImageWithHyperlink
        key={key}
        content={image}
        className="h-[576px] md:h-auto md:min-h-[320px]"
        textClassname={"font-roboto space-y-2 mb-2"}
        isBanner
      />
    );
  } else if (isComponentHeroBanner(image)) {
    return (
      <HeroBannerAutobarn
        key={key}
        content={image}
        className="h-[576px] md:h-auto md:min-h-[320px]"
        isBanner
      />
    );
  }
  return null;
};

// The main HeroBanner component remains the same
export const HeroBanner = ({
  content,
  className = "",
}: {
  content: SerializeFrom<
    Entry<TypeBannerCarouselSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
  >;
  className?: string;
}) => {
  return (
    <div className={cn(!content.fields.isFullWidth && "p-7")}>
      <Carousel
        className={cn(
          !content.fields.isFullWidth && "rounded-lg",
          "mx-0 w-full transition",
          className,
        )}
        modules={[Keyboard, Autoplay]}
        // code for pagination if it needs to be present again
        // modules={[Pagination, Keyboard, Autoplay]}
        // pagination={{
        //   bulletClass: "swiper-pagination-bullet",
        //   clickable: true,
        //   bulletActiveClass: "!bg-bannerActiveColor",
        // }}
        autoplay={{ delay: CAROUSEL_DELAY }}
        loop
        slidesPerView={1}
        useBubblesNavigation={content.fields.banner.length > 1}
        slideClassName="h-[576px] md:min-h-[320px] md:h-auto"
        keyboard={{ enabled: true }}
      >
        {content.fields.banner &&
          content.fields.banner.map((b, key) => getBanner(b, key))}
      </Carousel>
    </div>
  );
};

import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { InfoCircledIcon } from "@radix-ui/react-icons";

import type { HeroBannerContent } from "../types";
import { ContentfulCTA } from "./contentful-cta";
import { ContentfulPicture } from "./picture";
import PromoSticker from "./promo-sticker";
import { RichTextRender } from "./rich-text";
import TooltipWithRichText from "./tooltip-with-rich-text";

export const HeroBannerAutobarn = ({
  content,
  className = "",
  buttonClassname = "",
  textClassname = "",
  isBanner = false,
}: {
  content: HeroBannerContent;
  className?: string;
  buttonClassname?: string;
  textClassname?: string;
  isBanner?: boolean;
}) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();

  // Ensure there's a background image
  if (!fields.backgroundImage) return null;

  const colourTheme = fields.colourTheme;
  let justificationClass = "";

  switch (fields.justificationTabletDesktop) {
    case "Start":
      justificationClass = "md:justify-start";
      break;
    case "Center":
      justificationClass = "md:justify-center";
      break;
    case "End":
      justificationClass = "md:justify-end";
      break;
    default:
      justificationClass = "";
  }

  return (
    <div className={`relative flex h-full w-full flex-col ${className}`}>
      {/* Background Image */}
      {/* Set max height on xl desktop so that can limit banner scaling */}
      <div
        className="flex w-full items-center rounded-lg xl:max-h-[600px]"
        {...inspectorProps({
          entryId: content.sys.id,
          fieldId: "backgroundImage",
        })}
      >
        <ContentfulPicture
          image={fields.backgroundImage}
          className={className}
          isBanner={isBanner}
        />
      </div>

      {/* Content Overlay */}
      <div
        className={`absolute flex h-full w-full justify-center text-center ${justificationClass}`}
      >
        <div className="flex h-full flex-col items-center justify-end gap-4 py-10 md:w-1/2 md:justify-center lg:py-32">
          <div className="flex max-w-xs flex-col xl:max-w-lg ">
            {/* Eyebrow Headline */}
            {fields.eyebrowHeadline && (
              <p
                className={`font-roboto text-2xl font-bold uppercase  lg:text-[32px] xl:text-[52px]  ${
                  colourTheme === "Light" ? "text-black" : "text-white"
                }`}
              >
                {fields.eyebrowHeadline}
              </p>
            )}

            {/* Promo Sticker */}
            {fields.promoSticker && (
              <PromoSticker content={fields.promoSticker} />
            )}

            {/* Main Headline */}
            {fields.headline && (
              <h2
                className={`mx-auto mt-4 max-w-xs font-roboto text-2xl font-bold uppercase lg:text-[32px] xl:max-w-xl xl:text-[52px] ${
                  colourTheme === "Light" ? "text-black" : "text-white"
                }`}
              >
                {fields.headline}
              </h2>
            )}

            {/* Rich Text Body */}
            {fields.body && fields.body.fields.richText && (
              <div className="w-full max-w-xl">
                <RichTextRender
                  content={fields.body.fields.richText}
                  entryId={fields.body.sys.id}
                  textAlignment={fields.body.fields.textAlignment}
                  textClassname={`${textClassname} ${
                    colourTheme === "Light" ? "text-black" : "text-white"
                  }
                  text-base md:text-lg lg:text-xl mt-2
                  `}
                />
              </div>
            )}
          </div>

          {/* Call to Actions (CTAs) */}
          <div className="flex w-full max-w-xl flex-col flex-wrap items-center justify-center gap-4 text-center md:flex-row">
            {fields.cta &&
              fields.cta.map(cta => (
                <ContentfulCTA
                  key={cta.sys.id}
                  content={cta}
                  className={buttonClassname}
                  {...inspectorProps({
                    entryId: content.sys.id,
                    fieldId: "cta",
                  })}
                />
              ))}
          </div>

          {/* Tooltip */}
          {isBanner && fields.tooltipText && fields.tooltipDescriptionText && (
            <div className="absolute inset-x-0 bottom-4 inline-flex items-center justify-center gap-1 text-neutral-3 md:bottom-3">
              <TooltipWithRichText
                content={
                  <RichTextRender
                    content={fields.tooltipDescriptionText}
                    entryId={fields.tooltipDescriptionText.data.id}
                  />
                }
              >
                <InfoCircledIcon
                  height={16.67}
                  width={16.67}
                  className="cursor-pointer"
                  color={`${colourTheme === "Light" ? "black" : "white"}`}
                />
              </TooltipWithRichText>
              <p
                className={`${
                  colourTheme === "Light" ? "text-black" : "text-white"
                } w-fit text-xs font-bold `}
              >
                {fields.tooltipText}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import type { Entry } from "contentful";

import type { SerializeFrom } from "@remix-run/cloudflare";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { H2 } from "~/components/ui/text";

import type {
  TypeImageWithCtaSkeleton,
  TypeImageWithHyperlinkSkeleton,
  TypeTilesSkeleton,
} from "../compiled";
import { getMaxWidthClass } from "../util";
import { ContentfulImageWithCTA } from "./image-with-cta";
import { ContentfulImageWithHyperlink } from "./image-with-hyperlink";
import { ContentfulLink } from "./link";

type props = SerializeFrom<
  Entry<
    TypeImageWithCtaSkeleton | TypeImageWithHyperlinkSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    string
  >
>;
type imageWIthCTAProps = SerializeFrom<
  Entry<TypeImageWithCtaSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
>;
type imageWIthHyperLinkProps = SerializeFrom<
  Entry<TypeImageWithHyperlinkSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
>;

// Dummy classes to ensure Tailwind generates them
const dummyClasses = `
  grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6
  md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6
  lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 lg:grid-cols-6
`;

const getTile = (image: props) => {
  return image.sys.contentType.sys.id === "imageWithCta" ? (
    <ContentfulImageWithCTA
      content={image as imageWIthCTAProps}
      className="[&>img]:rounded-lg"
    />
  ) : (
    <ContentfulImageWithHyperlink
      key={image.sys.id}
      content={image as imageWIthHyperLinkProps}
      className="h-auto w-fit [&>img]:rounded-lg"
    />
  );
};

export const Tiles = ({
  content,
  className,
}: {
  content: SerializeFrom<
    Entry<TypeTilesSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
  >;
  className?: string;
  preview?: boolean;
}) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();

  // Get the Tailwind class for maxWidth
  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  return (
    <div className={`${className} ${maxWidthClass} mx-auto`}>
      <div className="mb-3 flex flex-row items-center justify-between">
        <span {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}>
          <H2 className="font-roboto text-[26px] font-semibold uppercase leading-lg text-brand-primary-black lg:text-[32px]">
            {fields.title}
          </H2>
        </span>

        {fields.link && (
          <ContentfulLink
            content={fields.link}
            className="text-[12px] font-bold leading-lg text-brand-primary-black underline lg:text-base lg:font-extrabold"
          />
        )}
      </div>
      <div
        className={`grid ${
          fields.numberOfColumnsMobile === 1 ? `gap-8` : `gap-3`
        } md:gap-5 grid-cols-${fields.numberOfColumnsMobile} md:grid-cols-${
          fields.numberOfColumnsTablet
        }  lg:grid-cols-${fields.numberOfColumnsDesktop}`}
      >
        {fields.content.map((image, key) => (
          <div key={key}>{getTile(image)}</div>
        ))}
      </div>
    </div>
  );
};

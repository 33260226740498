import type { LinkProps } from "@remix-run/react";
import { Link } from "@remix-run/react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { cn } from "~/lib/ui";

import type { LinkContent } from "../types";
import { ContentfulImage } from "./contentful-image";

const ICON_POSITION = {
  "Append Text": "flex-row",
  "Prepend Text": "flex-row-reverse",
};

export const ContentfulLink = ({
  content,
  className,
  ...linkProps
}: {
  content: LinkContent;
  className?: string;
} & Omit<LinkProps, "content" | "to">) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();

  const link = fields.link ?? "";

  return (
    <Link
      className={cn(
        className,
        ICON_POSITION[fields.iconPosition || "Append Text"],
        "flex w-fit items-center gap-2",
      )}
      to={link}
      title={fields.title}
      {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
      {...linkProps}
    >
      {fields.linkText}
      {fields.icon?.fields.file?.url && (
        <ContentfulImage
          imageUrl={fields.icon?.fields.file?.url}
          alt={fields.icon?.fields.file.fileName || "Link Icon"}
          maxWidth="1/2"
          className="h-5 w-5"
        />
      )}
    </Link>
  );
};
